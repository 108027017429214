import {
  Directive,
  Input,
  OnInit,
  OnDestroy,
  inject,
  effect,
} from "@angular/core";
import { TemplateRef, ViewContainerRef } from "@angular/core";
import { ScreenSizeService } from "./screen-size.service";

@Directive({
  selector: "[appShowAbove], [appShowBelow]",
  standalone: true,
})
export class ShowAboveBelowDirective implements OnInit {
  private templateRef = inject(TemplateRef);
  private viewContainer = inject(ViewContainerRef);
  private screenSizeService = inject(ScreenSizeService);

  @Input() appShowAbove: string | undefined;
  @Input() appShowBelow: string | undefined;

  constructor() {
    // Create the effect after initialization to ensure inputs are ready
    effect(() => {
      this.updateView(this.screenSizeService.screenSize());
    });
  }

  ngOnInit() {
    this.updateView(this.screenSizeService.screenSize());
  }

  private updateView(currentSize: string): void {
    this.viewContainer.clear();
    const breakpoints = ["xs", "sm", "md", "lg", "xl", "xxl"];
    const currentIdx = breakpoints.indexOf(currentSize);

    if (this.appShowAbove) {
      const minIdx = breakpoints.indexOf(this.appShowAbove);
      if (currentIdx > minIdx) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        return;
      }
    }

    if (this.appShowBelow) {
      const maxIdx = breakpoints.indexOf(this.appShowBelow);
      if (currentIdx <= maxIdx) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        return;
      }
    }
  }
}
